<script setup lang="ts">
import { computed, useAttrs } from "vue";
import { SwiperSlide as SwiperSlideOriginal } from "swiper/vue";
import { responsiveClass } from "@magnit/core/src/utilities/helpers";
import type { IResponsive } from "@magnit/core/types";

type ISlideSize = IResponsive<
  1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto"
>;

interface ISliderProps {
  size?: ISlideSize;
}

const props = defineProps<ISliderProps>();
defineOptions({ inheritAttrs: false });
const attrs = useAttrs();

const rootClass = computed(() => {
  return [...responsiveClass("slider-slide_size", props.size), attrs.class];
});
</script>

<script lang="ts">
export default {
  name: "SwiperSlide",
};
</script>

<template>
  <SwiperSlideOriginal class="slider-slide" :class="rootClass">
    <slot />
  </SwiperSlideOriginal>
</template>

<style lang="postcss">
.slider-slide {
  &&_size-auto {
    width: auto;
  }

  &&_size-1 {
    width: calc(
      ((100% - var(--pl-slider-offset)) / (12 / 1)) -
        (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 1)))
    );
  }

  &&_size-2 {
    width: calc(
      ((100% - var(--pl-slider-offset)) / (12 / 2)) -
        (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 2)))
    );
  }

  &&_size-3 {
    width: calc(
      ((100% - var(--pl-slider-offset)) / (12 / 3)) -
        (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 3)))
    );
  }

  &&_size-4 {
    width: calc(
      ((100% - var(--pl-slider-offset)) / (12 / 4)) -
        (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 4)))
    );
  }

  &&_size-5 {
    width: calc(
      ((100% - var(--pl-slider-offset)) / (12 / 5)) -
        (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 5)))
    );
  }

  &&_size-6 {
    width: calc(
      ((100% - var(--pl-slider-offset)) / (12 / 6)) -
        (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 6)))
    );
  }

  &&_size-7 {
    width: calc(
      ((100% - var(--pl-slider-offset)) / (12 / 7)) -
        (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 7)))
    );
  }

  &&_size-8 {
    width: calc(
      ((100% - var(--pl-slider-offset)) / (12 / 8)) -
        (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 8)))
    );
  }

  &&_size-9 {
    width: calc(
      ((100% - var(--pl-slider-offset)) / (12 / 9)) -
        (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 9)))
    );
  }

  &&_size-10 {
    width: calc(
      ((100% - var(--pl-slider-offset)) / (12 / 10)) -
        (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 10)))
    );
  }

  &&_size-11 {
    width: calc(
      ((100% - var(--pl-slider-offset)) / (12 / 11)) -
        (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 11)))
    );
  }

  &&_size-12 {
    width: calc(
      ((100% - var(--pl-slider-offset)) / (12 / 12)) -
        (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 12)))
    );
  }
}

@media (--pl-viewport-from-s) {
  .slider-slide {
    &&_size-auto-s {
      width: auto;
    }

    &&_size-1-s {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 1)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 1)))
      );
    }

    &&_size-2-s {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 2)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 2)))
      );
    }

    &&_size-3-s {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 3)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 3)))
      );
    }

    &&_size-4-s {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 4)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 4)))
      );
    }

    &&_size-5-s {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 5)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 5)))
      );
    }

    &&_size-6-s {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 6)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 6)))
      );
    }

    &&_size-7-s {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 7)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 7)))
      );
    }

    &&_size-8-s {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 8)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 8)))
      );
    }

    &&_size-9-s {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 9)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 9)))
      );
    }

    &&_size-10-s {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 10)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 10)))
      );
    }

    &&_size-11-s {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 11)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 11)))
      );
    }

    &&_size-12-s {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 12)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 12)))
      );
    }
  }
}

@media (--pl-viewport-from-m) {
  .slider-slide {
    &&_size-auto-m {
      width: auto;
    }

    &&_size-1-m {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 1)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 1)))
      );
    }

    &&_size-2-m {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 2)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 2)))
      );
    }

    &&_size-3-m {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 3)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 3)))
      );
    }

    &&_size-4-m {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 4)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 4)))
      );
    }

    &&_size-5-m {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 5)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 5)))
      );
    }

    &&_size-6-m {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 6)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 6)))
      );
    }

    &&_size-7-m {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 7)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 7)))
      );
    }

    &&_size-8-m {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 8)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 8)))
      );
    }

    &&_size-9-m {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 9)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 9)))
      );
    }

    &&_size-10-m {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 10)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 10)))
      );
    }

    &&_size-11-m {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 11)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 11)))
      );
    }

    &&_size-12-m {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 12)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 12)))
      );
    }
  }
}

@media (--pl-viewport-from-ml) {
  .slider-slide {
    &&_size-auto-ml {
      width: auto;
    }

    &&_size-1-ml {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 1)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 1)))
      );
    }

    &&_size-2-ml {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 2)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 2)))
      );
    }

    &&_size-3-ml {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 3)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 3)))
      );
    }

    &&_size-4-ml {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 4)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 4)))
      );
    }

    &&_size-5-ml {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 5)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 5)))
      );
    }

    &&_size-6-ml {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 6)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 6)))
      );
    }

    &&_size-7-ml {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 7)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 7)))
      );
    }

    &&_size-8-ml {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 8)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 8)))
      );
    }

    &&_size-9-ml {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 9)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 9)))
      );
    }

    &&_size-10-ml {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 10)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 10)))
      );
    }

    &&_size-11-ml {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 11)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 11)))
      );
    }

    &&_size-12-ml {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 12)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 12)))
      );
    }
  }
}

@media (--pl-viewport-from-l) {
  .slider-slide {
    &&_size-auto-l {
      width: auto;
    }

    &&_size-1-l {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 1)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 1)))
      );
    }

    &&_size-2-l {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 2)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 2)))
      );
    }

    &&_size-3-l {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 3)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 3)))
      );
    }

    &&_size-4-l {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 4)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 4)))
      );
    }

    &&_size-5-l {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 5)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 5)))
      );
    }

    &&_size-6-l {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 6)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 6)))
      );
    }

    &&_size-7-l {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 7)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 7)))
      );
    }

    &&_size-8-l {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 8)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 8)))
      );
    }

    &&_size-9-l {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 9)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 9)))
      );
    }

    &&_size-10-l {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 10)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 10)))
      );
    }

    &&_size-11-l {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 11)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 11)))
      );
    }

    &&_size-12-l {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 12)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 12)))
      );
    }
  }
}

@media (--pl-viewport-from-xl) {
  .slider-slide {
    &&_size-auto-xl {
      width: auto;
    }

    &&_size-1-xl {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 1)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 1)))
      );
    }

    &&_size-2-xl {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 2)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 2)))
      );
    }

    &&_size-3-xl {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 3)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 3)))
      );
    }

    &&_size-4-xl {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 4)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 4)))
      );
    }

    &&_size-5-xl {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 5)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 5)))
      );
    }

    &&_size-6-xl {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 6)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 6)))
      );
    }

    &&_size-7-xl {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 7)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 7)))
      );
    }

    &&_size-8-xl {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 8)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 8)))
      );
    }

    &&_size-9-xl {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 9)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 9)))
      );
    }

    &&_size-10-xl {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 10)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 10)))
      );
    }

    &&_size-11-xl {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 11)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 11)))
      );
    }

    &&_size-12-xl {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / 12)) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / 12)))
      );
    }
  }
}
</style>
