<script setup lang="ts">
import { computed, ref } from "vue";
import { Autoplay, Grid, Navigation, Pagination } from "swiper/modules";
import { Swiper } from "swiper/vue";
import { Swiper as SwiperClass } from "swiper";
import {
  responsiveClass,
  responsiveVariables,
} from "@magnit/core/src/utilities/helpers";
import type { IResponsiveOnly, IViewport } from "@magnit/core/types";
import ArrowIcon from "~/assets/svg/icons/arrow.svg";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";

type INavigationVisible = IResponsiveOnly<boolean>;
enum Viewport {
  xs = 320,
  s = 360,
  m = 560,
  ml = 768,
  l = 960,
  xl = 1280,
}

interface ISwiperOptions {
  navigation?: boolean;
  pagination?: {
    enabled: boolean;
  };
  slidesPerView?: number | "auto";
  spaceBetween?: number;
  slidesOffsetAfter?: number;
  slidesOffsetBefore?: number;
  grid?: {
    rows?: number;
    fill?: "row" | "column";
  };
}

interface ISliderProps {
  navigation?: boolean;
  pagination?: boolean;
  slidesPerView?: number | "auto";
  spaceBetween?: number;
  slidesOffsetAfter?: number;
  slidesOffsetBefore?: number;
  grid?: {
    rows?: number;
    fill?: "row" | "column";
  };
  loop?: boolean;
  autoplay?: boolean;
  breakpoints?: IResponsiveOnly<ISwiperOptions>;
}

const props = defineProps<ISliderProps>();
const emit = defineEmits<{
  slideChange: [value: SwiperClass];
  slideChangeByUser: [value: SwiperClass];
  slideClick: [value: SwiperClass, clickEvent: Event];
  navigationNext: [value: SwiperClass];
  navigationPrev: [value: SwiperClass];
}>();
const modules = [Autoplay, Grid, Navigation, Pagination];

const swiperRef = ref<SwiperClass>();
const hasPagination = ref<boolean>(props.pagination);
const navigationHiddenRef = reactive({
  prev: false,
  next: false,
});

const rootVariables = computed(() => {
  const gaps: IResponsiveOnly<string> = {};
  const offsets: IResponsiveOnly<string> = {};

  if (props.spaceBetween) {
    gaps.xs = `${props.spaceBetween}px`;
  }

  if (props.slidesOffsetBefore || props.slidesOffsetAfter) {
    offsets.xs = `${
      (props.slidesOffsetBefore || 0) + (props.slidesOffsetAfter || 0)
    }px`;
  }

  if (props.breakpoints) {
    for (const [key, value] of Object.entries(props.breakpoints)) {
      if (typeof value.spaceBetween !== "undefined") {
        gaps[key as IViewport] = `${value.spaceBetween}px`;
      }

      if (
        typeof value.slidesOffsetBefore !== "undefined" ||
        typeof value.slidesOffsetAfter !== "undefined"
      ) {
        offsets[key as IViewport] = `${
          (value.slidesOffsetBefore || 0) + (value.slidesOffsetAfter || 0)
        }px`;
      }
    }
  }

  return {
    ...responsiveVariables("--_g", gaps),
    ...responsiveVariables("--_so", offsets),
  };
});

const navigationClass = computed(() => {
  const navigation: INavigationVisible = {};

  if (props.navigation) {
    navigation.xs = props.navigation;
  }

  if (props.breakpoints) {
    for (const [key, value] of Object.entries(props.breakpoints)) {
      if (typeof value.navigation !== "undefined") {
        navigation[key as IViewport] = value.navigation;
      }
    }
  }

  return [...responsiveClass("slider-navigation_visible", navigation)];
});

const breakpoints = computed(() => {
  if (props.breakpoints) {
    const options: {
      [width: number]: ISwiperOptions;
    } = {};

    for (const [key, value] of Object.entries(props.breakpoints)) {
      options[Viewport[key as IViewport]] = value;
    }
    return options;
  }

  return undefined;
});

const onSwiper = (swiper: SwiperClass) => {
  swiperRef.value = swiper;
  onSwiperSlideChange(swiper);
};

const onSwiperSlideChange = (swiper: SwiperClass) => {
  emit("slideChange", swiper);
  navigationHiddenRef.next = !props.loop && swiper?.isEnd;
  navigationHiddenRef.prev = !props.loop && swiper?.isBeginning;
};
const onSwiperClick = (swiper: SwiperClass, event: Event) => {
  emit("slideClick", swiper, event);
};

const onNavigationNextClick = () => {
  if (swiperRef.value) {
    swiperRef.value.slideNext();
    emit("navigationNext", swiperRef.value);
  }
};

const onNavigationPrevClick = () => {
  if (swiperRef.value) {
    swiperRef.value.slidePrev();
    emit("navigationPrev", swiperRef.value);
  }
};

const touchStartIndex = ref(0);
const onSwiperTouchStart = (swiper: SwiperClass) => {
  touchStartIndex.value = swiper.realIndex;
};
const onSwiperTouchEnd = (swiper: SwiperClass) => {
  /**
   * nextTick важен, иначе swiper.realIndex еще не поменялся
   */
  nextTick(() => {
    if (touchStartIndex.value !== swiper.realIndex) {
      emit("slideChangeByUser", swiper);
    }
  });
};
const onSliderBreakpoint = (
  _swiper: SwiperClass,
  breakpointOptions: ISwiperOptions,
) => {
  hasPagination.value = Boolean(breakpointOptions.pagination?.enabled);
};
</script>

<template>
  <div class="slider-wrapper">
    <Swiper
      class="slider"
      :class="{ 'slider_with-pagination': hasPagination }"
      :modules="modules"
      :loop="props.loop"
      :autoplay="
        props.autoplay && {
          delay: 4000,
          disableOnInteraction: false,
        }
      "
      :navigation="{
        enabled: props.navigation,
        nextEl: '.slider-navigation_next',
        prevEl: '.slider-navigation_prev',
      }"
      :pagination="{
        enabled: props.pagination,
        clickable: true,
        bulletClass: 'swiper-pagination_pagination-bullet',
        bulletActiveClass: 'swiper-pagination_pagination-bullet-active',
      }"
      :space-between="props.spaceBetween"
      :slides-per-view="props.slidesPerView"
      :breakpoints="breakpoints"
      :style="rootVariables"
      :slides-offset-after="props.slidesOffsetAfter"
      :slides-offset-before="props.slidesOffsetBefore"
      @swiper="onSwiper"
      @slide-change="onSwiperSlideChange"
      @click="onSwiperClick"
      @touch-start="onSwiperTouchStart"
      @touch-end="onSwiperTouchEnd"
      @breakpoint="onSliderBreakpoint"
    >
      <slot />
    </Swiper>
    <div
      class="slider-navigation slider-navigation_next"
      :class="[
        ...navigationClass,
        {
          'slider-navigation_hidden': navigationHiddenRef.next,
        },
      ]"
      @click="onNavigationNextClick"
    >
      <ArrowIcon />
    </div>
    <div
      class="slider-navigation slider-navigation_prev"
      :class="[
        ...navigationClass,
        {
          'slider-navigation_hidden': navigationHiddenRef.prev,
        },
      ]"
      @click="onNavigationPrevClick"
    >
      <ArrowIcon />
    </div>
  </div>
</template>

<style lang="postcss">
.slider-wrapper {
  position: relative;
}

.slider-navigation {
  width: 44px;
  height: 44px;
  background: var(--pl-button-background-invert-primary-default);
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0 4px 22px rgb(20 20 20 / 10%));
  position: absolute;
  top: 50%;
  z-index: var(--pl-z-index-level1);
  display: none;

  &_prev {
    left: 0;
    transform: rotate(180deg) translate(50%, 50%);
  }

  &_next {
    right: 0;
    transform: translate(50%, -50%);
  }

  &_visible {
    display: flex;
  }

  &_hidden {
    display: none;
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0;
}

.swiper-pagination {
  z-index: var(--pl-z-index-level1);
  display: flex;
  justify-content: center;
  align-items: center;

  &_pagination-bullet {
    width: 6px;
    height: 6px;
    background: #121212;
    opacity: 0.2;
    cursor: pointer;
    border-radius: 50%;
    display: inline-block;

    &-active {
      opacity: 0.7;
    }
  }

  &_pagination-bullet + &_pagination-bullet {
    margin-left: 12px;
  }
}

.slider {
  --_g: 0;
  --_g-s: var(--_g);
  --_g-m: var(--_g-s);
  --_g-ml: var(--_g-m);
  --_g-l: var(--_g-ml);
  --_g-xl: var(--_g-l);
  --_so: 0px;
  --_so-s: var(--_so);
  --_so-m: var(--_so-s);
  --_so-ml: var(--_so-m);
  --_so-l: var(--_so-ml);
  --_so-xl: var(--_so-l);
  --pl-slider-gap: var(--_g);
  --pl-slider-offset: var(--_so);

  &_with-pagination {
    padding-bottom: var(--pl-unit-x6);
  }
}

@media (--pl-viewport-from-s) {
  .slider {
    --pl-slider-gap: var(--_g-s);
    --pl-slider-offset: var(--_so-s);
  }

  .slider-navigation {
    &_visible-true-s {
      display: flex;
    }

    &_visible-false-s {
      display: none;
    }

    &_hidden {
      display: none;
    }
  }
}

@media (--pl-viewport-from-m) {
  .slider {
    --pl-slider-gap: var(--_g-m);
    --pl-slider-offset: var(--_so-m);
  }

  .slider-navigation {
    &_visible-true-m {
      display: flex;
    }

    &_visible-false-m {
      display: none;
    }

    &_hidden {
      display: none;
    }
  }
}

@media (--pl-viewport-from-ml) {
  .slider {
    --pl-slider-gap: var(--_g-ml);
    --pl-slider-offset: var(--_so-ml);
  }

  .slider-navigation {
    &_visible-true-ml {
      display: flex;
    }

    &_visible-false-ml {
      display: none;
    }

    &_hidden {
      display: none;
    }
  }
}

@media (--pl-viewport-from-l) {
  .slider {
    --pl-slider-gap: var(--_g-l);
    --pl-slider-offset: var(--_so-l);
  }

  .slider-navigation {
    &_visible-true-l {
      display: flex;
    }

    &_visible-false-l {
      display: none;
    }

    &_hidden {
      display: none;
    }
  }
}

@media (--pl-viewport-from-xl) {
  .slider {
    --pl-slider-gap: var(--_g-xl);
    --pl-slider-offset: var(--_so-xl);
  }

  .slider-navigation {
    &_visible-true-xl {
      display: flex;
    }

    &_visible-false-xl {
      display: none;
    }

    &_hidden {
      display: none;
    }
  }
}
</style>
